<style>
.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
  visibility: visible !important;
  display: none !important;
}
</style>
<template>
  <v-container v-if="caseData">
    <v-row class="flex-column">
      <v-col class="pb-0">
        <h1>Case #{{ caseData.id }} {{ caseData.patient.last_name }}, {{ caseData.patient.first_name }}</h1>
        <h3 class="d-inline">Current Status:</h3> <span>{{ caseData.status }}</span>
        <h3 class="mt-2">Clinic Address</h3>
        <p class="mb-0">
          {{ formatAddress(caseData.location.address) }}
        </p>
      </v-col>

      <v-col>
        <v-tabs v-model="tab" >
          <v-tab>Patient Info</v-tab>
          <v-tab>Schedule
            <v-icon v-if="!hasAppointmentSet" right color="warning">fas fa-exclamation-circle</v-icon>
          </v-tab>
          <v-tab>Notes</v-tab>
          <v-tab>Billing</v-tab>
          <v-tab>Lien
            <v-icon v-if="!!$refs.lien && ($refs.lien.caseLien === null || $refs.lien.caseLien.status !== 'signed')"
                    right color="warning">
              fas fa-exclamation-circle
            </v-icon>
          </v-tab>
          <v-tab>
            Documents
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" class="pt-6">
          <v-tab-item>
            <PatientInfo :case-data="caseData" view-only />
          </v-tab-item>

          <v-tab-item>
            <patient-schedule @reload="this.getCase" :case-data="caseData" view-only />
          </v-tab-item>

          <v-tab-item>
            <case-notes :case-data="caseData" view-only />
          </v-tab-item>

          <v-tab-item>
            <billing-tab :case-data="caseData" />
          </v-tab-item>

          <v-tab-item eager>
            <lien-tab ref="lien" v-if="caseData" :case-data="caseData" view-only />
          </v-tab-item>

          <v-tab-item>
            <Files @reload="this.getCase" :case-data="caseData" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import CaseNotes from '../../components/Case/CaseLogs'
import LienTab from '../../components/Case/LienTab'
import PatientSchedule from '../../components/Case/PatientSchedule'
import AddressMixin from '../../mixins/AddressMixin'
import { DateTime } from 'luxon'
import BillingTab from '../../components/Case/BillingTab'
import Files from '../../components/Case/Files'
import PatientInfo from '../../components/Case/PatientInfo'

export default {
  name: 'CaseView',
  components: { PatientInfo, BillingTab, CaseNotes, LienTab, PatientSchedule, Files },
  mixins: [AddressMixin],
  props: {
    caseId: {
      type: String
    }
  },
  data: () => ({
    caseData: null,
    hasAppointmentSet: false,
    tab: 0
  }),
  created () {
    this.getCase()
  },
  methods: {
    getCase () {
      this.$store
        .dispatch('Case/getCase', this.caseId)
        .then(response => {
          // sort appointment dates
          response.appointments.sort((a, b) => {
            return DateTime.fromISO(b.date).valueOf() - DateTime.fromISO(a.date).valueOf()
          })

          this.caseData = response

          // check for next appointment
          const appointments = [...this.caseData.appointments]
          const filtered = appointments
            .filter(appointment => {
              return appointment.status === 'scheduled'
            })

          this.hasAppointmentSet = !!filtered[0]
        })
    }
  }
}
</script>

<template>
  <v-container>
    <new-case-form internal @created="handleCaseCreated" />
  </v-container>
</template>
<script>
import NewCaseForm from '../../components/Case/NewCaseForm'
import event, { Events } from '../../event'
export default {
  name: 'CaseCreate',
  components: { NewCaseForm },
  data: () => ({}),
  methods: {
    async handleCaseCreated () {
      event.emit(Events.SUCCESS, 'case created successfully')
      await this.$router.push({ name: 'IntakesIndex' })
    }
  }
}
</script>

<template>
  <v-container>
    <h1>Active Cases</h1>

    <div v-if="cases.length === 0" class="mt-2">
      <p>There are currently no active cases.</p>
    </div>
    <div v-else>
      <v-data-table :items="cases"
                    :headers="headers"
                    @click:row="goToCase($event.id)"
                    :server-items-length="totalCases"
                    :page.sync="requestParams.page"
                    :sort-by.sync="requestParams.orderBy"
                    :sort-desc.sync="requestParams.sortDesc"
                    :items-per-page.sync="requestParams.itemsPerPage">
        <template #item.patient_id="{ item }">
          {{ item.patient.first_name }} {{ item.patient.last_name }}
        </template>

        <template #item.location_id="{ item }">
          {{ item.location.name }}
        </template>

        <template #item.nextAppointment="{ item }">
          <span v-if="item.appointments.length > 0">{{ formatDate(item.appointments[0].date, 'ff') }}</span>
          <span v-else><v-icon color="warning">fas fa-exclamation-circle</v-icon> no appointment set</span>
        </template>

        <template #item.created_at="{ item }">
          {{ formatDate(item.created_at) }}
        </template>

      </v-data-table>
    </div>
  </v-container>
</template>
<script>
import event, { Events } from '../../event'
import DateMixin from '../../mixins/DateMixin'

export default {
  name: 'ActiveCases',
  mixins: [DateMixin],
  data: () => ({
    requestParams: {
      page: 1,
      itemsPerPage: 10,
      orderBy: 'id',
      sortDesc: false
    },
    totalCases: 0,
    cases: [],
    headers: [
      { text: 'Status', value: 'status' },
      { text: 'Clinic', value: 'organization.name' },
      { text: 'Patient Name', value: 'patient_id' },
      { text: 'Location', value: 'location_id' },
      { text: 'Next Appointment', value: 'nextAppointment', sortable: false }
    ]
  }),
  created () {
    this.getCases()
  },
  methods: {
    getCases () {
      this.$store.commit('loading', true)

      const requestParams = {
        status: 'open,intake',
        page: this.requestParams.page,
        limit: this.requestParams.itemsPerPage,
        orderBy: this.requestParams.orderBy || null,
        orderDir: this.requestParams.sortDesc ? 'desc' : 'asc',
        patient: true,
        location: true,
        scheduledAppointments: true,
        organization: true
      }

      event.emit(Events.LOADING, true)

      this.$store
        .dispatch('Case/getCases', requestParams)
        .then(response => {
          this.totalCases = response.totalCount
          this.cases = response.cases
          this.$store.commit('loading', false)
        })
        .finally(() => {
          event.emit(Events.LOADING, false)
        })
    },
    goToCase (caseId) {
      this.$router.push({ name: 'AttorneyCaseView', params: { caseId: caseId.toString() } })
    }
  }
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',[_vm._v("Active Cases")]),(_vm.cases.length === 0)?_c('div',{staticClass:"mt-2"},[_c('p',[_vm._v("There are currently no active cases.")])]):_c('div',[_c('v-data-table',{attrs:{"items":_vm.cases,"headers":_vm.headers,"server-items-length":_vm.totalCases,"page":_vm.requestParams.page,"sort-by":_vm.requestParams.orderBy,"sort-desc":_vm.requestParams.sortDesc,"items-per-page":_vm.requestParams.itemsPerPage},on:{"click:row":function($event){return _vm.goToCase($event.id)},"update:page":function($event){return _vm.$set(_vm.requestParams, "page", $event)},"update:sortBy":function($event){return _vm.$set(_vm.requestParams, "orderBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.requestParams, "orderBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.requestParams, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.requestParams, "sortDesc", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.requestParams, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.requestParams, "itemsPerPage", $event)}},scopedSlots:_vm._u([{key:"item.patient_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.patient.first_name)+" "+_vm._s(item.patient.last_name)+" ")]}},{key:"item.location_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.location.name)+" ")]}},{key:"item.nextAppointment",fn:function(ref){
var item = ref.item;
return [(item.appointments.length > 0)?_c('span',[_vm._v(_vm._s(_vm.formatDate(item.appointments[0].date, 'ff')))]):_c('span',[_c('v-icon',{attrs:{"color":"warning"}},[_vm._v("fas fa-exclamation-circle")]),_vm._v(" no appointment set")],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }